.admin-header {
  background-color: #fbfbfb;
  border-bottom: 1px solid #ddd;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.business-logo {
  color: #404eed;
  font-weight: 700;
  font-size: 50px;
  margin-right: -1rem; 
}

.mosouq-logo-tag {
  font-size: 27px;
  font-weight: 900;
  text-align: left;
  color: #404eed;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  height: 88px;
  margin-right: 3rem;
  margin-left: 2rem;
}

.logo img {
  height: 40px;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
  font-size: 18px;
  font-weight: 600;
  color: #404eed;
}

.nav-links a {
  text-decoration: none;
  font-weight: 600;
  position: relative;
  color: #404eed;
}
.nav-links .dropdown {
  position: relative;
}

.nav-links .dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 1;
}

.nav-links .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.nav-links .dropdown-content a:hover {
  background-color: #f1f1f1;
}

.nav-links .dropdown:hover .dropdown-content {
  display: block;
}

.nav-links .dropdown:hover a {
  background-color: #ddd;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-icon {
  cursor: pointer;
}

.nav-links a:hover {
  color: #404eed;
}

.business-button-header {
  width: 191px;
  height: 53px;
  padding: 17px 50px;
  border-radius: 33px;
  background: #404eed;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.2px;
  color: #ffffff;
  border: none;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .header-container {
    padding: 0 1rem;
    margin-right: 1rem;
    margin-left: 1rem;

  }

  .nav-links {
    margin-left: auto;
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    position: absolute;
    top: 88px;
    right: 0;
    background: #fbfbfb;
    flex-direction: column;
    width: 100%;
    text-align: center;
    gap: 0;
    padding: 10px 0;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links a {
    padding: 10px 0;
  }

  .business-button-header {
    width: 100%;
    margin-top: 10px;
  }

  .menu-icon {
    display: block;
  }
}

@media (max-width: 480px) {
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    height: 100px;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
  }
  

  .nav-links a {
    font-size: 14px;
  }

  .business-button-header {
    font-size: 12px;
  }


}

.profile-dropdown {
  position: relative;
}

.profile-dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  width: 170px;
  background-color: #FBFBFB;
}

.profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 10px;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropdown-menu a {
  display: block;
  padding: 15px;
  color: #333;
  text-decoration: none;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}
