.main-profile-head {
    overflow: hidden;
    margin-right: 4rem;
    margin-left: 3rem;
    margin-top: 5rem;
}

.business-prof-setup-head {
    font-weight: 500;
    font-size: 26px;
    text-align: center    ;
    margin-top: 4rem;
}

.business-prof-descrp {
    font-weight: 400;
    font-size: 20px;
    text-align: center; 
    color: #666;
    /* margin-top: 0.5rem; */
}

.continue-btn{
    background-color: #404EED;
    color: white;
    border-radius: 50px;
    padding: 10px;
    text-align: center;
    margin-bottom: 2rem;
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
}

.back-btn{
    /* background-color: #404EED; */
    color: black;
    border-radius: 50px;
    border: 1px solid black;
    padding: 10px;
    text-align: center;
    margin-bottom: 2rem;
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
}

.input-field {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 50px;
    border: 1px solid #ccc;
    font-size: 16px;
    box-sizing: border-box;
}

.text-field {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    box-sizing: border-box;
}


.label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.image-upload {
    position: relative;
    cursor: pointer;
}

.image-upload img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
}

.file-input {
    display: none;
}


.d-flex.gap-2 {
    gap: 0.5rem; /* Decrease gap between elements */
  }
  
  .card-body {
    padding: 0.75rem; /* Adjust padding to compact the design */
  }
  
  .form-check-input {
    margin: 0; /* Remove extra space around checkboxes */
  }
  
  .text-muted {
    font-size: 0.875rem; /* Optionally reduce font size of "to" */
  }
  