.main-body {
  overflow: scroll;
  height: 100vh;
  width: 100%;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 5rem;
}
@media (max-width: 768px) {
  .main-body {
    overflow: scroll;
    height: 100vh;
    width: 100%;
    margin-top: 5rem;
    overflow-x: hidden;
  }
}

@media (max-width: 480px) {
  .main-body {
    overflow: scroll;
    height: 100vh;
    width: 100%;
    margin-top: 5rem;
    overflow-x: hidden !important;

  }
}
